<template>
  <v-container id="system" fluid tag="section">
    <v-row>
      <!-- <v-col cols="2"><v-btn @click="tryToConfirmStop">Stop</v-btn></v-col> -->
      <v-col cols="2"
        ><v-btn @click="tryToConfirmRestart">Restart</v-btn></v-col
      >
    </v-row>

    <confirm-dialog
      max_width="400px"
      :text="confirmTxtRestart"
      :dialogFlag="dlgConfirmRestart"
      :cancelFunc="closeConfirmRestart"
      :confirmFunc="restart"
    ></confirm-dialog>

    <confirm-dialog
      max_width="400px"
      :text="confirmTxtStop"
      :dialogFlag="dlgConfirmStop"
      :cancelFunc="closeConfirmStop"
      :confirmFunc="stop"
    ></confirm-dialog>

      <v-overlay :value="overlay">
        <v-progress-circular
        :size="70"
        :width="7"
        color="purple"
        indeterminate
      ></v-progress-circular>
      </v-overlay>

  </v-container>
</template>

<script>
import { stop_system, restart_system } from "@/api";

import ConfirmDialog from "@/components/base/ConfirmDialog";

import { OVERLAY_TIMEOUT, } from "@/constants";

export default {
  name: "SystemPanel",
  components: {
    ConfirmDialog,
  },

  destroyed() {
    // clearInterval(this.timer);
  },

  mounted: async function () {
    // this.timer = setInterval(() => {
    // }, 3000);
  },

  watch: {},

  data() {
    return {
      overlay: false,
      confirmTxtRestart: "Are you sure to restart the system?",
      confirmTxtStop: "Are you sure to stop the system?",
      dlgConfirmRestart: false,
      dlgConfirmStop: false,
      timer: -1,
    };
  },

  methods: {
    tryToConfirmRestart() {
      this.dlgConfirmRestart = true;
    },
    closeConfirmRestart() {
      this.dlgConfirmRestart = false;
    },
    tryToConfirmStop() {
      this.dlgConfirmStop = true;
    },
    closeConfirmStop() {
      this.dlgConfirmStop = false;
    },
    stop() {
      console.log("try to restart...");
      stop_system();
      this.closeConfirmStop();
    },
    restart() {
      console.log("try to restart...");
      restart_system();
      this.closeConfirmRestart();
      this.overlay = true;
      setTimeout(() => {
        this.overlay = false;
      }, OVERLAY_TIMEOUT)
    },
  },
};
</script>
